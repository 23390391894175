.pointer {
  cursor: pointer;
}

.centered-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade-out {
  animation: fadeOut 0.3s;
  opacity: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}

.refresh-icon {
  position: absolute;
  top: 17px;
  right: 2rem;
  width: 2.2rem;
  cursor: pointer;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeOut 0.3s;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dropzone {
  border: 1px dotted gray !important;
  padding: 10px !important;
  margin-bottom: 15px;
}

.rbc-calendar {
  min-height: 70vh;
}

.rbc-overlay {
  z-index: 1000000000;
}

.rounded-circle {
  border: 0.5px solid lightgray;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.modal-block-custom-add {
  display: block !important;
}

.rmsc .dropdown-container {
  padding: 5.2px !important;
  border: 1px solid #FAFAFA !important;
  box-shadow: 1px 1px 8px 2px rgb(0 0 0 / 10%) !important;
  border-radius: 5px !important;
}

.multiselect-error-message {
  float: right;
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 0.2rem;
  margin-bottom: 0.7rem;
}

.error-border {
  border: 1px solid red !important;
  border-radius: 6px !important;
}

.rmsc .gray {
  color: black !important;
}

.language-change-buttons {
  position: absolute;
  top: 0;
  right: 0;
}

.disabled {
  pointer-events: none;
}

/* Map Styles */
.map-heading {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #281414;
  z-index: 10;
  margin: 0;
  padding: 0;
}

.map-search {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}

.hospital-profile-map-search {
  position: absolute;
  top: 12.7rem;
  left: 60%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}

.map-locate {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  z-index: 10;
}

.hospital-profile-map-locate {
  position: absolute;
  top: 12.7rem;
  right: 3rem;
  background: none;
  border: none;
  z-index: 10;
}

.hospital-profile-map-locate img {
  width: 40px;
  cursor: pointer;
}

.map-locate img {
  width: 40px;
  cursor: pointer;
}

.search-filter-section .media img {
  border: none !important;
}

.active-nav {
  border-bottom: 5px solid #417EBF;
}

.user-dashboard .contact-info a {
  width: 35px !important;
  height: 33px !important;
}


.horizontal-scroll-container {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  gap: 15px;
}

.horizontal-scroll-container::-webkit-scrollbar {
  height: 2px; /* Adjust the height for horizontal scrollbar */
}

.horizontal-scroll-container::-webkit-scrollbar-track {
  background: transparent; /* You can change the background if needed */
}

.horizontal-scroll-container::-webkit-scrollbar-thumb {
  background: #3e7ec0; /* Blue color for scrollbar */
  border-radius: 10px; /* Rounded corners for scrollbar */
}

/* Custom scrollbar for Firefox */
.horizontal-scroll-container {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #3e7ec0 transparent; /* Blue color for scrollbar */
}

.doctor-card {
  min-width: 200px;
  max-width: 200px;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doctor-card .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.doctor-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.doctor-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.doctor-specialty {
  font-size: 14px;
}

.contact-info {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.contact-icon {
  font-size: 20px;
}

.no-doctors {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}

.list-card {
  border-radius: 2rem;
  min-width: 14rem;
  height: 14rem;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.list-card img {
  width: 5rem;
  border-radius: 50%;
}

.list-card strong {
  margin-top: 2px;
}

.edit-doctor-link {
  position: absolute;
  top: 1rem;
  right: 1rem;
  text-decoration: underline;
}

.card div video {
  width: 100%; 
  height: 200px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
  padding: 0px;
}

/* DROPDOWN */
.custom-select {
  height: 3.2rem !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6"><path fill="black" d="M0 0l5 5 5-5H0z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 10px;
  padding-right: 30px;
}

/* Confirmation Dialog */
.confirmation-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-dialog-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 25rem;
}

.confirmation-dialog-content button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirmation-dialog-content button:first-child {
  background: #007bff;
  color: white;
}

.confirmation-dialog-content button:last-child {
  background: #6c757d;
  color: white;
}

.date-filter-btn {
  width: 3rem;
  height: 3rem;
  border: none;
  background: #417EBF;
  color: white;
  border-radius: 6px;
  padding-top: 3px;
  margin: 0px 10px;
  cursor: pointer;
}

.gray-out {
  opacity: 0.5;
  background-color: #f0f0f0;
}

.send-lab-request {
  min-width: 60rem;
}


/* LAB RESULT */
.card-header {
  background-color: #6697CB;
  color: white;
  padding: 1rem 2rem;
  cursor: pointer;
  font-weight: bold;
}

.or-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 1rem 0;
}

.or-separator::before,
.or-separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
  margin: 0 1rem;
}

.file-upload-area {
  position: relative;
  border: 2px dashed #ccc;
  padding: 1rem;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 1rem;
}

.file-drag-drop {
  color: #666;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.lab-card-body {
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.collapse-shevron {
  border: none;
  background: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.collapse-shevron:focus {
  outline: none;
  box-shadow: none;
}

.collapse-shevron:focus-visible {
  outline: none;
}

.collapse-shevron:active {
  border: none;
  outline: none;
  box-shadow: none;
}

.completed-status {
  text-transform: capitalize;
  color: green;
  font-weight: bold;
}

.pending-status {
  text-transform: capitalize;
  color: #e1ad01;
  font-weight: bold;
}

.profile-img-cross {
  position: absolute;
  top: 1rem;
  left: 2rem;
  cursor: pointer;
}

.service-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  background: #fff;
}

.service-card:hover {
  transform: translateY(-5px); /* Slight hover effect */
}

.service-card img {
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 18rem;
  margin-bottom: 0rem;
}

.service-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.service-card .card-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0rem;
}

.service-card .description {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit description to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0rem;
  position: relative;
}

.service-card .description:hover::after {
  content: attr(title);
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 10;
  left: 0;
  top: 0;
  width: 95%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  white-space: normal;
}

.service-card .badge {
  margin-right: 5px;
}

.provider-card {
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
  height: 95%;
}

.provider-card:hover {
  transform: translateY(-5px);
}

.provider-card img {
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.provider-card .badge {
  font-size: 0.8rem;
  padding: 5px 10px;
  font-weight: 600;
}

.provider-card .card-title {
  font-size: 1.1rem;
  font-weight: 600;
}

.provider-card .specialities-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
  justify-content: center;
}

.speciality-chip {
  background-color: #e7f1ff;
  font-size: 0.75rem;
  padding: 5px;
  border-radius: 5px;
}

.provider-card .card-text {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.provider-card button {
  width: 100%;
}

.provider-card .card-text.description {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-dialog.modal-dialog-centered.modal-xl {
  max-width: 55%;
}

.color-blue {
  color: #417ebf !important;
  font-weight: bolder !important;
}