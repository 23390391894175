.confirm-modal-content {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
}

.confirmation-image {
  width: 100px;
  margin: 0 auto;
  display: block;
}

.confirm-text-center {
  text-align: center;
  color: #333;
}

.confirm-text-center strong {
  color: #417ebf;
}

.resend-link {
  color: #417ebf;
  text-decoration: none;
  font-weight: bold;
}

.resend-link:hover {
  text-decoration: underline;
}

@media (max-width: 576px) {
  .confirm-modal-content {
    padding: 1.5rem;
  }

  .confirmation-image {
    width: 80px;
  }
}

.color-blue {
  color: #417ebf !important;
  font-weight: bolder !important;
}
